import style from './Preorder.module.scss';

const Preorder = ({ className, reverse }) => {
  return (
    <div
      className={`${style.preorder} ${reverse && style.reverse} ${className}`}
    >
      <div className={style.preorder__btn}>INSTANT BUY</div>
      <div>
        $HiVE Coin
        <span> Presale live now!</span>
      </div>
    </div>
  );
};

export default Preorder;
