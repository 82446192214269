import style from './Payment.module.scss';
import title from './assets/title.png';
import titleMobile from './assets/title-mobile.svg';
import hive from './assets/hive.png';
import eth from './assets/eth.svg';
import usdt from './assets/usdt.svg';
import card from './assets/card.svg';
import hat from './assets/hat.png';
import img from './assets/img.svg';
import { useMediaQuery } from 'usehooks-ts';
import { useTimer } from 'react-timer-hook';
import { Zoom } from 'react-reveal';

const Payment = ({ mode, className }) => {
  const expiryTimestamp = new Date(1704056399 * 1000);
  const isMobile = useMediaQuery('(max-width:767px)');

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  });

  return (
    <Zoom>
      <div className={`${style.payment} ${mode && style.mode} ${className}`}>
        {mode ? (
          <>
            <div className={style.payment__icon}>
              <img src={img} alt='' />
            </div>
            <div className={style.payment__title}>
              <span>PRE-ORDER</span> YOUR <br />
              $HiVE NODE TODAY!
            </div>
            <div className={style.payment__label}>
              Preorder Now for February Delivery
            </div>
          </>
        ) : (
          <>
            <div className={style.payment__title}>
              <img src={isMobile ? titleMobile : title} alt='' />
            </div>
            <div className={style.payment__label}>
              erc20 layer 2 launch January 2024
            </div>
            <div className={style.paymentTimer}>
              <div className={style.paymentTimer__hat}>
                <img src={hat} alt='' />
              </div>

              <div className={style.paymentTimer__item}>
                <span className={style.value}>{days}</span>
                <span className={style.label}>days</span>
              </div>
              <div className={style.paymentTimer__item}>
                <span className={style.value}>:</span>
                <span className={style.label}></span>
              </div>
              <div className={style.paymentTimer__item}>
                <span className={style.value}>{hours}</span>
                <span className={style.label}>hours</span>
              </div>
              <div className={style.paymentTimer__item}>
                <span className={style.value}>:</span>
                <span className={style.label}></span>
              </div>
              <div className={style.paymentTimer__item}>
                <span className={style.value}>{minutes}</span>
                <span className={style.label}>minutes</span>
              </div>
              <div className={style.paymentTimer__item}>
                <span className={style.value}>:</span>
                <span className={style.label}></span>
              </div>
              <div className={style.paymentTimer__item}>
                <span className={style.value}>{seconds}</span>
                <span className={style.label}>seconds</span>
              </div>
            </div>
            <div className={style.paymentPrice}>
              <span>Actual price: $0.08</span>
              <span>Next price: $0.09</span>
            </div>
            <div className={style.paymentRaise}>
              <div
                style={{ '--percent': 40 }}
                className={style.paymentRaise__line}
              >
                <span></span>
              </div>
            </div>
            <div className={style.paymentRaised}>
              USD Raised:
              <span>$ 1,814,209 / $ 2,200,000</span>
            </div>
          </>
        )}
        <div className={style.paymentBuy}>
          <div className={style.paymentBuy__title}>Instant buy</div>
          <div className={style.paymentBuy__body}>
            <div className={style.paymentBuy__bodyItem}>
              <img src={eth} alt='' />
              ETH
            </div>
            {!mode && (
              <div className={style.paymentBuy__bodyItem}>
                <img src={usdt} alt='' />
                USDT
              </div>
            )}
            <div className={style.paymentBuy__bodyItem}>
              <img src={card} alt='' />
            </div>
          </div>
          <div className={style.paymentBuy__calc}>
            <label className={style.input}>
              <div className={style.input__title}>Pay with ETH</div>
              <div className={style.inputItem}>
                <div className={style.inputItem__icon}>
                  <img src={eth} alt='' />
                </div>
                <input
                  defaultValue={0}
                  type='number'
                  className={style.inputItem__count}
                />
              </div>
            </label>
            <label className={style.input}>
              <div className={style.input__title}>Receive $HiVE</div>
              <div className={style.inputItem}>
                <div className={style.inputItem__icon}>
                  <img src={hive} alt='' />
                </div>
                <input
                  defaultValue={0}
                  type='number'
                  className={style.inputItem__count}
                />
              </div>
            </label>
          </div>
          <div className={style.paymentBuy__connect}>
            <span>CONNECT WALLET</span>
          </div>
          <div className={style.paymentBuy__bottom}>
            <a href=''>$hiVE 🚀</a>
            <a href=''>Claim tokens💰</a>
          </div>
        </div>
      </div>
    </Zoom>
  );
};

export default Payment;
