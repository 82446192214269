import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from 'react-parallax-mouse';
import style from './Hive.module.scss';
import hiveMp4 from './assets/hive.mp4';
import hiveWebm from './assets/hive.mp4';
import { useMediaQuery } from 'usehooks-ts';

const Hive = ({ className, small, big }) => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <div
      className={`${style.hive} ${className} ${small && style.small} ${
        big && style.big
      }`}
    >
      {!isMobile && (
        <MouseParallaxContainer
          className={`${style.hive__parallax}`}
          useWindowMouseEvents
        >
          <MouseParallaxChild factorX={0.015} factorY={0.03}>
            <video
              preload='auto'
              playsInline
              webkit-playsInline
              loop
              autoPlay
              muted
            >
              <source src={hiveWebm} type='video/webm' />
              <source src={hiveMp4} type='video/mp4' />
            </video>
          </MouseParallaxChild>
        </MouseParallaxContainer>
      )}
    </div>
  );
};

export default Hive;
