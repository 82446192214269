import { Fade } from 'react-reveal';
import style from './SectionTitle.module.scss';

const SectionTitle = ({ children, small, className }) => {
  return (
    <Fade bottom>
      <div className={`${style.title} ${small && style.small} ${className}`}>
        {children}
      </div>
    </Fade>
  );
};

export default SectionTitle;
