import { Fade } from 'react-reveal';
import AdvantagesCard from '../../components/AdvantagesCard';
import Hive from '../../components/Hive';
import Navigation from '../../components/Navigation';
import SectionTitle from '../../components/SectionTitle';
import Layer from '../Layer';
import style from './Oracale.module.scss';
import icon1 from './assets/icon1.png';
import icon2 from './assets/icon2.png';
import icon3 from './assets/icon3.png';
import icon4 from './assets/icon4.png';

const OracaleSection = () => {
  return (
    <section id='oracle' className={style.oracale}>
      <Layer />
      <div className={`${style.con} container`}>
        <Hive className={style.con__hive} small />
        <Hive className={style.con__hive} />
        <Hive className={style.con__hive} big />

        <Navigation className={style.navigation} />
        <SectionTitle className={style.oracaleTop}>
          <Hive className={style.oracale__hive} />
          $HiVE <span>ORACLE</span>
        </SectionTitle>
        <Fade bottom cascade>
          <div className={style.oracale__text}>
            <p>
              $HiVE is your gateway to real-time market data in the
              cryptocurrency space.
            </p>
            <p>
              As a cryptocurrency Oracle, we specialize in delivering accurate
              and up-to-the-minute information that empowers traders, investors,
              and DeFi enthusiasts.
            </p>
            <p>
              Our commitment to data reliability is unwavering, as we source
              information from market participants, including exchanges, market
              makers, and financial services providers.
            </p>
          </div>
        </Fade>
        <SectionTitle small className={style.oracaleTop}>
          <span>Key Features</span> of $HiVE Oracle:
        </SectionTitle>
        <div className={style.oracaleBody}>
          <Hive className={style.oracaleBody__hive} />
          <Hive className={style.oracaleBody__hive} small />
          <AdvantagesCard
            className={style.oracaleBody__item}
            icon={icon1}
            title={'Real-Time Updates'}
            text={
              'We provide timely updates with a refresh rate as frequent as every 500 milliseconds, ensuring you have access to the latest market insights.'
            }
          />
          <AdvantagesCard
            className={style.oracaleBody__item}
            icon={icon2}
            title={'Data Accuracy'}
            text={
              'Our stringent data quality standards guarantee that the information you receive is trustworthy and dependable, allowing you to make well-informed decisions.'
            }
          />
          <AdvantagesCard
            className={style.oracaleBody__item}
            icon={icon3}
            title={'Market Participant Integration'}
            text={
              '$HiVE aggregates data from a widerange of market participants, ensuring comprehensive coverage and a holistic view of the cryptocurrency landscape.'
            }
          />
          <AdvantagesCard
            className={style.oracaleBody__item}
            icon={icon4}
            title={'Empowering DeFi'}
            text={
              "Whether you're involved in decentralized finance (DeFi) projects, trading, or investment, $HiVE's data services are designed to enhance your decision-making process."
            }
          />
        </div>
        <Fade>
          <div className={style.oracale__text}>
            <p>
              Join us in harnessing the power of real-time cryptocurrency market
              data. With $HiVE, you can stay ahead of the curve and navigate the
              dynamic world of digital assets with confidence.
            </p>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default OracaleSection;
