import { useMediaQuery } from 'usehooks-ts';
import Hive from '../../components/Hive';
import Navigation from '../../components/Navigation';
import Preorder from '../../components/Preorder';
import SectionTitle from '../../components/SectionTitle';
import style from './Tokenomics.module.scss';
import img from './assets/img.png';
import imgMobile from './assets/img-mobile.png';
import { Fade } from 'react-reveal';
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from 'react-parallax-mouse';

const TokenomicsSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section id='tokenomics' className={style.tokenomics}>
      <div className='container'>
        <Navigation className={style.navigation} />
        <div className={style.tokenomicsTop}>
          <SectionTitle className={style.tokenomicsTop__title}>
            $HiVE <span>TOKENOMICS</span>
          </SectionTitle>
          {!isMobile && (
            <Preorder reverse className={style.tokenomicsTop__preorder} />
          )}
        </div>
        <div className={style.tokenomicsBody}>
          <Hive className={style.tokenomics__hive} small />
          <Hive className={style.tokenomics__hive} small />
          <Hive className={style.tokenomics__hive} small />
          <Hive className={style.tokenomics__hive} big />
          <Hive className={style.tokenomics__hive} small />
          <Hive className={style.tokenomics__hive} />
          <Fade>
            <MouseParallaxContainer inverted>
              <MouseParallaxChild factorX={0.005} factorY={0.02}>
                <img src={isMobile ? imgMobile : img} alt='' />
              </MouseParallaxChild>
            </MouseParallaxContainer>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default TokenomicsSection;
