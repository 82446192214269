import { useMediaQuery } from 'usehooks-ts';
import style from './Header.module.scss';
import logo from './assets/logo.png';
import tg from '../InfoBlock/assets/ds.jsx';
import x from '../InfoBlock/assets/x.jsx';
import yt from '../InfoBlock/assets/yt.jsx';
import { useEffect, useState } from 'react';
import { linksContent } from '../../links.js';
import { Zoom } from 'react-reveal';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleBurger = () => {
    setBurgerOpen(!burgerOpen);
  };

  const toSection = (sectionId) => {
    const section = document.querySelector(`${sectionId}`);
    setBurgerOpen(false);
    section.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset >= '16') {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <header className={`${style.header} ${scrolled && style.scrolled}`}>
      <div className='container'>
        <Zoom>
          <div className={style.headerBody}>
            <div className={style.headerBody__logo}>
              <img src={logo} alt='' />
            </div>

            {isMobile && (
              <div className={style.social}>
                <a
                  href={linksContent.telegram}
                  target='_blank'
                  className={style.social__item}
                >
                  {tg}
                </a>
                <a
                  href={linksContent.twitter}
                  target='_blank'
                  className={style.social__item}
                >
                  {x}
                </a>
                <a href='' target='_blank' className={style.social__item}>
                  {yt}
                </a>
              </div>
            )}

            {isMobile && (
              <div
                onClick={() => toggleBurger()}
                className={style.header__burger}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='24'
                  viewBox='0 0 25 24'
                  fill='none'
                >
                  <path
                    d='M3.8125 12H21.8125M3.8125 6H21.8125M3.8125 18H15.8125'
                    stroke='#F5A300'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            )}
            <div
              className={`${style.headerLinks} ${burgerOpen && style.active}`}
            >
              <div
                onClick={() => toSection('#oracle')}
                className={style.headerLinks__item}
              >
                ORACLE
              </div>
              <div
                onClick={() => toSection('#staking')}
                className={style.headerLinks__item}
              >
                STAKING
              </div>
              <div
                onClick={() => toSection('#bridging')}
                className={style.headerLinks__item}
              >
                BRIDGING
              </div>
              <div
                onClick={() => toSection('#tokenomics')}
                className={style.headerLinks__item}
              >
                TOKENOMICS
              </div>
              <div
                onClick={() => toSection('#roadmap')}
                className={style.headerLinks__item}
              >
                ROADMAP
              </div>
              <a
                href='./whitepaper.pdf'
                target='_blank'
                className={style.headerLinks__item}
              >
                WHITEPAPER
              </a>
              <div
                onClick={() => toSection('#about')}
                className={style.headerLinks__item}
              >
                ABOUT
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </header>
  );
};

export default Header;
