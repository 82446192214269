import { Zoom } from 'react-reveal';
import AdvantagesCard from '../../components/AdvantagesCard';
import Hive from '../../components/Hive';
import Navigation from '../../components/Navigation';
import SectionTitle from '../../components/SectionTitle';
import style from './Bridging.module.scss';
import icon from './assets/icon.png';
import icon1 from './assets/icon1.png';
import icon2 from './assets/icon2.png';
import icon3 from './assets/icon3.png';
import icon4 from './assets/icon4.png';
import icon5 from './assets/icon5.png';
import icon6 from './assets/icon6.png';
import icon7 from './assets/icon7.png';
import Hives from '../../components/Hives';

const BridgingSection = () => {
  return (
    <section id='bridging' className={style.bridging}>
      <div className='container'>
        <Hive className={style.bridging__hive} small />

        <Navigation className={style.navigation} />
        <SectionTitle className={style.bridging__title}>
          $HiVE <span>BRIDGING</span>
        </SectionTitle>
        <div className={style.bridgingTop}>
          <Zoom>
            <div className={style.bridgingInfo}>
              <div className={style.bridgingInfo__icon}>
                <img src={icon} alt='' />
              </div>
              <div className={style.bridgingInfo__title}>
                Introducing HiVE Network Bridge: <br /> Revolutionizing{' '}
                <span>BRC20</span> and
                <span> ERC20</span> Token Transfers
              </div>
              <div className={style.bridgingInfo__text}>
                <p>
                  Experience seamless cross-chain token transfers like never
                  before with HiVE Network Bridge, a testament to our unwavering
                  commitment to user experience.
                </p>
                <p>
                  Designed with both safety and ease in mind, HiVE Network
                  Bridge emerges as a reliable tool, revolutionizing the way
                  users interact with BRC20 tokens across multiple chains.
                </p>
              </div>
            </div>
          </Zoom>
          <Zoom>
            <Hives className={style.bridgingVideo} />
          </Zoom>
        </div>
        <SectionTitle small className={style.secondTitle}>
          Why Choose <span>HiVE Network</span> Bridge?
        </SectionTitle>
        <div className={style.bridgingBody}>
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon1}
            title={'Seamless Cross-Chain Transfers'}
            text={
              'Effortlessly bridge BRC20 tokens to other chains, redefining simplicity and efficiency in token transfers.'
            }
          />
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon2}
            title={'Enhanced Security and Usability'}
            text={
              'Our user-centric approach ensures a safe and intuitive experience, prioritizing your peace of mind in every transaction.'
            }
          />
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon3}
            title={'Developer-Friendly Platform'}
            text={
              'We invite developers to tap into HiVE’s potential, creating innovative cross-chain applications that redefine the DeFi landscape.'
            }
          />
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon4}
            title={'First Dual-Sided Bridge'}
            text={
              'HiVE Network stands as the first of its kind, offering dual-sided bridging capabilities for easy transfers between BRC20 and ERC20 tokens.'
            }
          />
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon5}
            title={'Unparalleled Liquidity'}
            text={
              'By facilitating fluid cross-network transfers, HiVE Network propels liquidity to new heights, enhancing the accessibility and utility of BRC and ERC tokens.'
            }
          />
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon6}
            title={'Mission-Driven Development'}
            text={
              'Our core mission is straightforward – to amplify liquidity and accessibility for BRC and ERC tokens in a secure, user-centric environment.'
            }
          />
          <AdvantagesCard
            className={style.bridgingBody__item}
            icon={icon7}
            title={'Your Gateway to Advanced Token Interoperability'}
            text={
              "HiVE Network isn't just a tool; it's a gateway to the future of decentralized finance. <br/><br/> By bridging the gap between BRC20 and ERC20 tokens, we're not only streamlining transactions but also fostering a more interconnected and fluid blockchain ecosystem. <br/><br/> Embrace the next level of token interoperability with HiVE Bridge. Whether you're a user seeking efficient token transfer or a developer aspiring to build cross-chain solutions, HiVE Network is your platform of choice."
            }
          />
        </div>
      </div>
    </section>
  );
};

export default BridgingSection;
