const ds = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_47_40)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.03739 11.2933C8.23887 8.59144 12.3742 6.81019 14.4433 5.94958C20.351 3.49236 21.5786 3.06551 22.3787 3.05142C22.5547 3.04832 22.9481 3.09193 23.203 3.29875C23.4182 3.47337 23.4774 3.70927 23.5058 3.87484C23.5341 4.04041 23.5694 4.41758 23.5413 4.71228C23.2212 8.07603 21.8359 16.2389 21.1312 20.0064C20.833 21.6005 20.2458 22.1351 19.6774 22.1874C18.442 22.301 17.504 21.371 16.3075 20.5866C14.4352 19.3593 13.3774 18.5953 11.5601 17.3977C9.45976 16.0136 10.8213 15.2529 12.0183 14.0097C12.3315 13.6844 17.7745 8.73353 17.8799 8.28441C17.893 8.22824 17.9053 8.01887 17.7809 7.90831C17.6565 7.79775 17.4729 7.83556 17.3404 7.86563C17.1527 7.90825 14.1616 9.88519 8.3674 13.7965C7.51841 14.3794 6.74943 14.6635 6.06044 14.6486C5.30089 14.6322 3.83981 14.2191 2.75365 13.8661C1.42144 13.433 0.362616 13.2041 0.454816 12.4686C0.502839 12.0855 1.03036 11.6938 2.03739 11.2933Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_47_40'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default ds;
