import { Fade } from 'react-reveal';
import SectionTitle from '../../components/SectionTitle';
import style from './Layer.module.scss';

const Layer = () => {
  return (
    <div className={style.layer}>
      <div className='container'>
        <SectionTitle className={style.bridging__title}>
          HRC-20 Layer 1 Blockchain Mainnet Launches February 2024
        </SectionTitle>
        <div className={style.bridgingInfo}>
          <Fade bottom>
            <div className={style.bridgingInfo__title}>
              Introducing HiVE Network Bridge: <br /> Revolutionizing{' '}
              <span>BRC20</span> and
              <span> ERC20</span> Token Transfers
            </div>
          </Fade>
          <Fade cascade bottom>
            <div className={style.bridgingInfo__text}>
              <p>
                Customized Consensus Mechanism with the ability to implement a
                tailor-made consensus protocol that aligns with specific network
                needs, optimizing for speed, efficiency, and security.
              </p>
              <p>
                Foundation for DApps and Smart Contracts which provides a solid
                and reliable foundation for deploying decentralized applications
                (DApps) and smart contracts, fostering innovation and utility in
                the blockchain space.
              </p>
              <p>
                The launch of Hive Network Layer 1 represents a strategic move
                towards establishing a robust, scalable, and versatile
                blockchain platform. It positions the network to address current
                and future challenges in the blockchain domain
              </p>
              <p>
                $HiVE Coin L2 Launches January 2024 as a Network solution for
                the Ethereum Mainnet
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Layer;
