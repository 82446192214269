import style from './Marquee.module.scss';
import MarqueeComponent from 'react-fast-marquee';

const Marquee = () => {
  return (
    <MarqueeComponent className={style.marquee}>
      Presale is LIVE | Stage 1: SOLD OUT | Current price: $0.08 | Next Price:
      $0.09 | Beware Of Scammers | $HiVE Coin Launch January | HRC-20 Mainnet
      Launch February |
    </MarqueeComponent>
  );
};

export default Marquee;
