import { Fade } from 'react-reveal';
import Accordion from '../../components/Accordion';
import SectionTitle from '../../components/SectionTitle';
import style from './Faq.module.scss';
import Hive from '../../components/Hive';

const FaqSection = () => {
  return (
    <section className={style.faq}>
      <div className='container'>
        <Hive className={style.faq__hive} small />
        <Hive className={style.faq__hive} />
        <Hive className={style.faq__hive} big />

        <SectionTitle>
          F<span>A</span>Q’s
        </SectionTitle>
        <div className={style.faqBody}>
          <Accordion
            faq={{
              question:
                'How does $HiVE ensure the security and privacy of its users?',
              answer: [
                '$HiVE is deeply committed to security and privacy.',
                'It employs advanced cryptographic techniques and a robust decentralized network structure to protect user data and transactions.',
                "Regular security audits and compliance with leading industry standards further reinforce the platform's defenses against potential threats.",
              ],
            }}
          />
          <Accordion
            faq={{
              question: 'Is $HiVE accessible to non-technical users?',
              answer: [
                'Absolutely. $HiVE is designed to be user-friendly and accessible to everyone, regardless of their technical background. ',
                'The platform offers intuitive interfaces and comprehensive support resources to ensure that all users can easily navigate and make the most of its features.',
              ],
            }}
          />
          <Accordion
            faq={{
              question:
                'What are the rewards for staking on the $HiVE platform?',
              answer: [
                'Running a Hive Node on the $HiVE network allows you to actively participate in validating and propagating transactions and blocks.',
                'It enhances network security and decentralization and offers the advantage of solo mining.',
                "Hive Node operators also gain access to exclusive features and rewards, contributing significantly to the network's growth and development.",
              ],
            }}
          />
          <Accordion
            faq={{
              question:
                'Can I participate in governance decisions on the $HiVE platform?',
              answer: [
                'Yes, $HiVE often includes governance features where token holders can participate in decision-making processes. This may include voting on key protocol changes, development directions, and other significant aspects of the platform.',
                "This democratic approach ensures that the $HiVE ecosystem evolves in alignment with the community's interests and needs.",
              ],
            }}
          />
          <Accordion
            faq={{
              question: 'How can developers benefit from the $HiVE ecosystem?',
              answer: [
                'Developers can greatly benefit from the $HiVE ecosystem by accessing its open-source tools and APIs for creating decentralized applications (DApps).',
                'The platform supports innovation and development, offering resources for developers to build, test, and deploy their DApps.',
                'Additionally, the $HiVE community provides a supportive environment for collaboration and growth.',
              ],
            }}
          />
          <Accordion
            faq={{
              question:
                'What are the rewards for staking on the $HiVE platform?',
              answer: [
                'Running a Hive Node on the $HiVE network allows you to actively participate in validating and propagating transactions and blocks.',
                'It enhances network security and decentralization and offers the advantage of solo mining.',
                "Hive Node operators also gain access to exclusive features and rewards, contributing significantly to the network's growth and development.",
              ],
            }}
          />
        </div>
        <Fade bottom>
          <div className={style.faq__text}>
            For additional information or specific inquiries, we welcome you to
            connect with our dedicated team on the HiVE Telegram channel.
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default FaqSection;
