import { Fade, Zoom } from 'react-reveal';
import AdvantagesCard from '../../components/AdvantagesCard';
import Hive from '../../components/Hive';
import Navigation from '../../components/Navigation';
import Payment from '../../components/Payment';
import SectionTitle from '../../components/SectionTitle';
import style from './Mode.module.scss';
import icon from './assets/icon.png';
import icon1 from './assets/icon1.png';
import icon2 from './assets/icon2.png';
import icon3 from './assets/icon3.png';
import icon4 from './assets/icon4.png';
import icon5 from './assets/icon5.png';
import img from './assets/img.png';

const ModeSection = () => {
  return (
    <section id='node' className={style.mode}>
      <div className='container'>
        <Hive className={style.mode__hive} small />
        <Hive className={style.mode__hive} />
        <Hive className={style.mode__hive} small />

        <Navigation className={style.navigation} />

        <SectionTitle className={style.mode__title}>
          $HiVE <span>NODE</span>
        </SectionTitle>
        <div className={style.img}>
          <Zoom>
            <img src={img} alt='' />
          </Zoom>
        </div>
        <div className={style.modeTop}>
          <Payment mode className={style.modeTop__payment} />
          <Fade>
            <div className={style.modeTop__text}>
              <div className={style.modeTop__textIcon}>
                <img src={icon} alt='' />
              </div>
              <div className={style.modeTop__textTitle}>
                Introducing the HIVE Node - Powered by $HiVE.
              </div>
              <div className={style.modeTop__textText}>
                <p>Introducing the HIVE Node - Powered by $HiVE.</p>
                <p>
                  Embrace the forefront of blockchain technology by operating a
                  HIVE Node, powered by $HiVE, the cornerstone node software For
                  the HIVE cryptocurrency Network.
                </p>
                <p>
                  As a HIVE Node operator, you're not just a user; you become a
                  pivotal element in the HIVE ecosystem, contributing directly
                  to its strength and decentralization.
                </p>
                <p>
                  <span>Pre-order Now for February Delivery!</span>
                </p>
                <p>
                  Reserve your HIVE Node today and be among the first to access
                  this cutting-edge technology in February. By Pre-ordering, you
                  position yourself at the vanguard of the HIVE ecosystem, ready
                  to harness its full potential.
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <SectionTitle small className={style.secondTitle}>
          <Hive className={style.mode__hive} />
          What Does Operating a <span>HiVE Node</span> Entail?
        </SectionTitle>
        <div className={style.modeBody}>
          <Hive className={style.modeBody__hive} big />
          <Hive className={style.modeBody__hive} />
          <Hive className={style.modeBody__hive} small />

          <AdvantagesCard
            className={style.modeBody__item}
            icon={icon1}
            title={'Active Network Participation'}
            text={
              'Engage in the vital processes of validating and propagating transactions and blocks within the HIVE network.'
            }
          />
          <AdvantagesCard
            className={style.modeBody__item}
            icon={icon2}
            title={'Enhance Network Security'}
            text={
              "Your participation bolsters the network's resilience and decentralization, pivotal in safeguarding the blockchain's integrity."
            }
          />
          <AdvantagesCard
            className={style.modeBody__item}
            icon={icon3}
            title={'Support and Nurture the Ecosystem'}
            text={
              'Your involvement goes beyond mining; it supports the continuous growth and development of the HIVE network.'
            }
          />
          <AdvantagesCard
            className={style.modeBody__item}
            icon={icon4}
            title={'Solo Mining Capability'}
            text={
              'Break free from the constraints of mining pools. With a HiVE Node, you have the independence to mine solo, directly benefiting from your efforts.'
            }
          />
          <AdvantagesCard
            className={style.modeBody__item}
            icon={icon5}
            title={'Exclusive Access to Features and Rewards'}
            text={
              'Operating a HIVE Node unlocks a world of unique features and lucrative rewards, exclusive to node operators.'
            }
          />
        </div>
        <AdvantagesCard
          className={style.modeText}
          text={
            "Your role in driving innovation and growth in the HiVE ecosystem is just a click away. pre-order now and be prepared for February's exciting launch!"
          }
        />
      </div>
    </section>
  );
};

export default ModeSection;
