import style from './InfoBlock.module.scss';
import tg from './assets/ds.jsx';
import x from './assets/x.jsx';
import yt from './assets/yt.jsx';
import { linksContent } from '../../links.js';
import { Zoom } from 'react-reveal';

const InfoBlock = () => {
  return (
    <div className={style.info}>
      <div className='container'>
        <div className={style.infoBody}>
          <Zoom>
            <div className={style.social}>
              <a
                href={linksContent.telegram}
                target='_blank'
                className={style.social__item}
              >
                {tg}
              </a>
              <a
                href={linksContent.twitter}
                target='_blank'
                className={style.social__item}
              >
                {x}
              </a>
              <a href='' target='_blank' className={style.social__item}>
                {yt}
              </a>
            </div>
          </Zoom>
          <Zoom>
            <div className={style.infoBody__text}>
              February 2024: Mainnet Launch of $HiVE Network (<span>HRC20</span>
              ).
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
