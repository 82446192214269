import { useMediaQuery } from 'usehooks-ts';
import Hive from '../../components/Hive';
import Payment from '../../components/Payment';
import style from './Main.module.scss';
import logo from './assets/logo.png';
import logoMobile from './assets/logo-mobile.png';
import { Fade, Zoom } from 'react-reveal';
import Hives from '../../components/Hives';

const MainSection = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.main}>
      <Hive className={style.main__hive} />
      <Hive className={style.main__hive} />
      <Hive className={style.main__hive} small />
      <Hive className={style.main__hive} small />
      <Hive className={style.main__hive} big />

      {children}
      <div className='container'>
        <div className={style.mainBody}>
          {isMobile && (
            <div className={style.mainBody__label}>
              February 2024: Mainnet Launch of <br /> $HiVE Network (
              <span>HRC20</span>
              ).
            </div>
          )}
          <div className={style.mainBody__info}>
            <div className={style.mainBody__infoImg}>
              <Zoom>
                <img src={isMobile ? logoMobile : logo} alt='' />
              </Zoom>
            </div>
            <Fade bottom>
              <div className={style.mainBody__infoText}>
                Next-Gen AI-Integrated blockchain ecosystem: <br />
                Layer 1 blockchain <span>HRC-20 - $HIVE Coin</span> Layer 2
                solutions - <span>HiVE Oracle - HIVE Bridge</span>
              </div>
            </Fade>
          </div>
          <Hives className={style.main__hives} />
          <Payment className={style.mainBody__payment} />
        </div>
      </div>
    </section>
  );
};

export default MainSection;
