import style from './App.module.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Marquee from './components/Marquee';
import InfoBlock from './components/InfoBlock';
import MainSection from './modules/Main';
import AboutSection from './modules/About';
import RoadmapSection from './modules/Roadmap';
import TokenomicsSection from './modules/Tokenomics';
import OracaleSection from './modules/Oracale';
import StakingSection from './modules/Staking';
import ModeSection from './modules/Mode';
import BridgingSection from './modules/Bridging';
import FaqSection from './modules/Faq';
import PartnersSection from './modules/Partners';
import bg from './assets/bg.mp4';

function App() {
  return (
    <div className={`wrapper`}>
      <div className={style.bg}>
        <video
          preload='auto'
          playsInline
          webkit-playsInline
          loop
          autoPlay
          muted
          src={bg}
        ></video>
      </div>
      <div className={`content`}>
        <Marquee />
        <MainSection>
          <InfoBlock />
          <Header />
        </MainSection>
        <Marquee />
        <AboutSection />
        <Marquee />
        <OracaleSection />
        <Marquee />
        <StakingSection />
        <Marquee />
        <ModeSection />
        <Marquee />
        <BridgingSection />
        <Marquee />
        <RoadmapSection />
        <Marquee />
        <TokenomicsSection />
        <Marquee />
        <div className={style.footer}>
          <PartnersSection />
          <FaqSection />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
