import { useMediaQuery } from 'usehooks-ts';
import Navigation from '../../components/Navigation';
import style from './About.module.scss';
import { Fade } from 'react-reveal';
import Hive from '../../components/Hive';

const AboutSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section id='about' className={style.about}>
      <div className='container'>
        <Navigation />
        <div className={style.aboutBody}>
          <Hive className={style.aboutBody__hive} small />
          <Hive className={style.aboutBody__hive} small />
          <Hive className={style.aboutBody__hive} big />
          <Hive className={style.aboutBody__hive} small />
          <Hive className={style.aboutBody__hive} />

          {!isMobile && (
            <Fade>
              <div className={style.aboutBody__video}>
                <iframe
                  src='https://www.youtube.com/embed/VMBWdYbeWuQ?si=_kst_mOwpqG3Ox9n'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowfullscreen
                ></iframe>
              </div>
            </Fade>
          )}
          <div className={style.aboutBody__content}>
            <Fade bottom>
              <div className={style.aboutBody__contentTitle}>
                About <span>$HiVE</span> Network
              </div>
            </Fade>
            <Fade bottom>
              <div className={style.aboutBody__contentCard}>
                Welcome to Hive Network, a decentralized ecosystem that combines
                Layer 2 Scalability, Oracle services, BRC20 bridging, nodes,
                staking - and so much more.
              </div>
            </Fade>
            {isMobile && (
              <Fade bottom>
                <div className={style.aboutBody__video}>
                  <iframe
                    src='https://www.youtube.com/embed/VMBWdYbeWuQ?si=_kst_mOwpqG3Ox9n'
                    title='YouTube video player'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowfullscreen
                  ></iframe>
                </div>
              </Fade>
            )}
            <Fade bottom>
              <div className={style.aboutBody__contentCard}>
                We bring real-world data 'off-chain 'and bring it 'on-chain'
                through our smart contracts making decentralized applications
                smarter and more versatile.
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
