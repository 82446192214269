const x = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_47_43)'>
      <g clip-path='url(#clip1_47_43)'>
        <path
          d='M14.0615 10.3155L22.0753 1H20.1763L13.2179 9.08852L7.66015 1H1.25L9.65434 13.2313L1.25 23H3.14914L10.4975 14.4583L16.3668 23H22.7769L14.0615 10.3155ZM11.4603 13.339L10.6088 12.1211L3.83343 2.42965H6.7504L12.2182 10.2509L13.0697 11.4689L20.1772 21.6354H17.2602L11.4603 13.339Z'
          fill='white'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_47_43'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_47_43'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default x;
