import { Zoom } from 'react-reveal';
import style from './Navigation.module.scss';
import { navigationLinks } from './links';

const Navigation = ({ activeName, className }) => {
  const toSection = (sectionId) => {
    const section = document.querySelector(`${sectionId}`);
    section.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Zoom>
      <div className={`${style.navigation} ${className}`}>
        {navigationLinks.map((item, index) =>
          item.to.split('#').length >= 2 ? (
            <div
              key={index}
              onClick={() => toSection(item.to)}
              className={style.navigation__item}
            >
              {item.name}
            </div>
          ) : (
            <a
              key={index}
              href={item.to}
              target='_blank'
              className={style.navigation__item}
            >
              {item.name}
            </a>
          )
        )}
      </div>
    </Zoom>
  );
};

export default Navigation;
