import style from './Modal.module.scss';

const Modal = ({ toggleModal, isOpen, text }) => {
  return (
    <div className={`${style.modal} ${isOpen && style.open}`}>
      <div className={style.modal__bg} onClick={() => toggleModal(false)}></div>
      <div
        className={style.modalBody}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
};

export default Modal;
