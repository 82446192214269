import SectionTitle from '../../components/SectionTitle';
import style from './Staking.module.scss';
import img from './assets/img.jpg';
import icon from './assets/icon.png';
import Navigation from '../../components/Navigation';
import Hive from '../../components/Hive';
import { Fade, Zoom } from 'react-reveal';

const StakingSection = () => {
  return (
    <section id='staking' className={style.staking}>
      <div className='container'>
        <Hive className={style.staking__hive} />
        <Hive className={style.staking__hive} big />
        <Hive className={style.staking__hive} small />
        <Hive className={style.staking__hive} big />

        <Navigation className={style.navigation} />

        <SectionTitle>
          $HiVE <span>STAKING</span>
        </SectionTitle>

        <div className={style.stakingBody}>
          <Zoom bottom>
            <div className={style.stakingBody__info}>
              <div className={style.stakingBody__infoIcon}>
                <img src={icon} alt='' />
              </div>
              <div className={style.stakingBody__infoTitle}>
                <Fade bottom>Unlock Earnings as a Liquidity Provider</Fade>
              </div>
              <Fade cascade bottom>
                <div className={style.stakingBody__infoText}>
                  <p>
                    By participating as a liquidity provider for a duration of
                    30 days, you have the opportunity to earn an impressive APY
                    of up to 200%, with compounded interest.
                  </p>
                  <p>
                    Your assets, including Bitcoin (BTC), Tether (USDT),
                    Ethereum (ETH), and Solana (SOL), will be utilized within
                    our liquidity pool.{' '}
                  </p>
                  <p>
                    This approach allows you to not only contribute to the
                    liquidity of the platform but also generate potential
                    earnings through your assets.
                  </p>
                  <p>
                    We believe in providing opportunities for growth and value,
                    making your participation both professionally rewarding and
                    educational in the world of decentralized finance (DeFi).
                  </p>
                </div>
              </Fade>
            </div>
          </Zoom>
          <Zoom right>
            <div className={style.stakingBody__img}>
              <img src={img} alt='' />
            </div>
          </Zoom>
        </div>
      </div>
    </section>
  );
};

export default StakingSection;
