export const navigationLinks = [
  {
    name: 'ORACLE',
    to: '#oracle',
  },
  {
    name: 'STAKING',
    to: '#staking',
  },
  {
    name: 'NODE',
    to: '#node',
  },
  {
    name: 'BRIDGING',
    to: '#bridging',
  },
  {
    name: 'TOKENOMICS',
    to: '#tokenomics',
  },
  {
    name: 'ROADMAP',
    to: '#roadmap',
  },
  {
    name: 'WHITEPAPER',
    to: './whitepaper.pdf',
  },
  {
    name: 'ABOUT',
    to: '#about',
  },
];
