import { Fade } from 'react-reveal';
import Hive from '../../components/Hive';
import Navigation from '../../components/Navigation';
import Preorder from '../../components/Preorder';
import SectionTitle from '../../components/SectionTitle';
import style from './Partners.module.scss';
import img from './assets/partners.png';

const PartnersSection = () => {
  return (
    <section className={style.partners}>
      <div className='container'>
        <Navigation className={style.partners__links} />
        <div className={style.partnersTop}>
          <Hive className={style.partners__hive} />
          <Preorder className={style.partnersTop__preorder} />
        </div>
        <SectionTitle className={style.partnersTop__title}>
          <span>PARTNERS</span>
        </SectionTitle>
        <div className={style.partnersBody}>
          <div className={style.partnersBody__img}>
            <Fade>
              <img src={img} alt='' />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
