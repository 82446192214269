import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from 'react-parallax-mouse';
import style from './Hives.module.scss';
import apiaryWebm from './assets/apiary.webm';
import apiaryMp4 from './assets/apiary.mp4';

const Hives = ({ className }) => {
  return (
    <div className={`${style.hive} ${className}`}>
      <MouseParallaxContainer useWindowMouseEvents inverted>
        <MouseParallaxChild factorX={0.015} factorY={0.03}>
          <video
            preload='auto'
            playsInline
            webkit-playsInline
            loop
            autoPlay
            muted
          >
            <source src={apiaryWebm} type='video/webm' />
            <source src={apiaryMp4} type='video/mp4' />
          </video>
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </div>
  );
};

export default Hives;
