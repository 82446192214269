import style from './Footer.module.scss';
import tg from '../InfoBlock/assets/ds.jsx';
import x from '../InfoBlock/assets/x.jsx';
import yt from '../InfoBlock/assets/yt.jsx';
import logo from './assets/logo.png';
import Hive from '../Hive/index.jsx';
import { linksContent } from '../../links.js';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className='container'>
        <div className={style.footerBody}>
          <div className={style.footerCol}>
            <div className={style.footerBody__logo}>
              <img src={logo} alt='' />
            </div>
            <div className={style.footerBody__social}>
              <a href={linksContent.telegram}>{tg}</a>
              <a href={linksContent.twitter}>{x}</a>
              <a href=''>{yt}</a>
            </div>
          </div>
          <div className={style.footerCol}>
            <div className={style.footerCol__title}>About</div>
            <ul className={style.footerCol__list}>
              <li>
                <a href='./whitepaper.pdf' target='_blank'>
                  Whitepaper
                </a>
              </li>
              <li>
                <a href=''>Partners</a>
              </li>
            </ul>
          </div>
          <div className={style.footerCol}>
            <div className={style.footerCol__title}>Product</div>
            <ul className={style.footerCol__list}>
              <li>
                <a href=''>hIve bRIDGE</a>
              </li>
              <li>
                <a href=''>Token</a>
              </li>
              <li>
                <a href=''>cEX LISTING</a>
              </li>
            </ul>
          </div>
          <div className={style.footerCol}>
            <div className={style.footerCol__title}>Others</div>
            <ul className={style.footerCol__list}>
              <li>
                <a href=''>Roadmap</a>
              </li>
              <li>
                <a href=''>FAQ</a>
              </li>
              <li>
                <a href=''>Press</a>
              </li>
            </ul>
          </div>
          <div className={style.footerCol}>
            <div className={style.footerCol__title}>Contact</div>
            <ul className={style.footerCol__list}>
              <li>
                <a href='mailto:info@HIVENETWORK.IO'>info@HIVENETWORK.IO</a>
              </li>
              <li>CA: 0x99336911B14878063a9552E7F0537af5d4ea6c43</li>
            </ul>
            <div className={style.footerCol__btn}>BUY NOW</div>
          </div>
        </div>
        <div className={style.footerBottom}>
          <Hive className={style.footerBottom__hive} />
          <div className={style.footerBottom__cr}>
            Copyright <span>© 2023</span>
          </div>
          <div className={style.footerBottom__rights}>
            All rights reserved bY HIVE NETWORK
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
