import { Fade } from 'react-reveal';
import style from './AdvantagesCard.module.scss';

const AdvantagesCard = ({ icon, title, text, className }) => {
  return (
    <Fade bottom>
      <div className={`${style.card} ${className}`}>
        {icon && (
          <div className={style.card__icon}>
            <img src={icon} alt='' />
          </div>
        )}
        {title && <div className={style.card__title}>{title}</div>}
        {text && (
          <div
            className={style.card__text}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        )}
      </div>
    </Fade>
  );
};

export default AdvantagesCard;
